export const paths = {
    home: '/',
    main: '/leagues',

    signup: '/signup',
    rules: '/rules',
    top: '/top',
    profile: '/profile',
    verification: '/verification',
    reset: '/reset-password',
    account: '/delete-account'
};


import { styled } from '@mui/material/styles';

const InfoText = styled('h1')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    color: 'white',
    textShadow: '-2px 0 black, 0 2px black, 2px 0 black, 0 -2px black',
});

export default InfoText;